interface LogLevels {
  DEBUG: number;
  INFO: number;
  WARN: number;
  ERROR: number;
  NONE: number;
}

const LOG_LEVELS: LogLevels = {
  DEBUG: 0,
  INFO: 1,
  WARN: 2,
  ERROR: 3,
  NONE: 4
};

class Logger {
  operationId: string | null;
  currentLevel: number;

  constructor() {
      this.operationId = null;
      this.currentLevel = LOG_LEVELS.INFO;
  }

  setOperationId(operationId: string | null): void {
      this.operationId = operationId;
  }

  clearOperationId(): void {
      this.operationId = null;
  }

  setLevel(level: number): void {
      this.currentLevel = level;
  }

  debug(message: string, ...args: any[]): void {
      if (this.currentLevel <= LOG_LEVELS.DEBUG) {
          console.debug(this.formatMessage('DEBUG', message), ...args);
      }
  }

  info(message: string, ...args: any[]): void {
      if (this.currentLevel <= LOG_LEVELS.INFO) {
          console.info(this.formatMessage('INFO', message), ...args);
      }
  }

  warn(message: string, ...args: any[]): void {
      if (this.currentLevel <= LOG_LEVELS.WARN) {
          console.warn(this.formatMessage('WARN', message), ...args);
      }
  }

  error(message: string, ...args: any[]): void {
      if (this.currentLevel <= LOG_LEVELS.ERROR) {
          console.error(this.formatMessage('ERROR', message), ...args);
      }
  }

  formatMessage(levelTag: string, message: string): string {
      const operationPart = this.operationId ? `[${this.operationId}]` : '';
      return `[${levelTag}]${operationPart} ${message}`;
  }
}

export const logger = new Logger();
export const LogLevel: LogLevels = LOG_LEVELS;
