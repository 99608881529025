import {useContext} from "react";
import {WrapperContext} from "../context/WrapperContext";
export const Header = () => {
    const {wrapperState: {transaction}} = useContext(WrapperContext);

    const MXPesos = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN',
    });
    return (
        <header className="block-heading">
            <img src="https://www.coca-colaentuhogar.com/static/frontend/Bounteous/mexico/es_MX/images/logo.svg" alt="en tu hogar by Coca-Cola logo"/>
            <p>Las bebidas favoritas de tu familia con beneficios exclusivos.</p>
            <p>Total a pagar: {MXPesos.format(parseFloat(transaction.total_amount ?? ''))}</p>
        </header>
    );
};
