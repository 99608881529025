import {useCallback, useContext, useEffect} from "react";
import {WrapperContext} from "../context/WrapperContext";
import {useLoginForm} from '../hooks/useLoginForm';
import {MercadoPagoComponent} from "../gateways/MercadoPagoComponent";
import {WrapperError} from "../errors/WrapperError";
import {WrapperErrorMessages} from "../errors/WrapperErrorMessages";
import {useBrowserVersion} from '../hooks/useBrowserVersion';
import Spinner from './Spinner';
import { logger } from "../utils/logger";


const queryParams = new URLSearchParams(window.location.search)
export const LoginForm = () => {
    const {wrapperState: {bottler, error, loading}, setError} = useContext(WrapperContext),
          {initLogin} = useLoginForm(),
          {checkBrowser} = useBrowserVersion();

    const getPaymentComponent = useCallback(() => {
        if (bottler.payment_ga_id && !error.status) {
            switch (bottler.payment_ga_id) {
                case 'MERCADOPAGO':
                    return <MercadoPagoComponent />
                default:
                    return '';
            }
        }
    }, [bottler.payment_ga_id, error]);

    useEffect(() => {
        const transactionId = queryParams.get('token');
        if(!transactionId) {
            logger.error(WrapperErrorMessages.MISSING_PARAMS.message);
            setError(WrapperErrorMessages.WRAPPER_GENERIC);
            return;
        }
        initLogin(transactionId);
    }, [initLogin, setError]);

    useEffect(() => {
        if(!checkBrowser()){
            setError({status: true, message: WrapperErrorMessages.BROWSER_VERSION.message, retry: false, redirect: true});
        }    
    }, [checkBrowser, setError]);

    return (
        <div id="loginForm">
            <WrapperError />
            {loading ?  ( <Spinner /> ) :   getPaymentComponent()  }
        </div>
    );
};
