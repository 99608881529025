import {WrapperContext} from "./WrapperContext";
import {Bottler, ConsumerResponse, Customer, Transaction, WrapperState} from "../models/WrapperStateModel";
import {useCallback, useReducer} from "react";
import {wrapperReducer} from "./wrapperReducer";

const INITIAL_STATE: WrapperState = {
    customer: {
        token_cds: '',
        email: '',
        enrichment_data: {},
        cardsIds: [],
        saved_card: {
            status: false,
            error_message: '',
            is_loading: false
        },
    },
    transaction: {
        payment: {},
        success_card: {},
    },
    consumerResponse: {
        transaction_status: 'cancelled',
        date_approved: null,
        payment_response: null,
        process_id: "0"
    },
    bottler: {},
    error: {
        status: false,
        message: ''
    },
    redirectOn: {
        status: false,
        payment_status: false
    },
    token: '',
    statusM: false,
    loading: true
}

interface WrapperProviderProps {
    children: JSX.Element | JSX.Element[]
}

export const WrapperProvider = ({ children }: WrapperProviderProps) => {

    const [wrapperState, dispatch] = useReducer( wrapperReducer, INITIAL_STATE );

    const setBottler = useCallback((bottler: Bottler | object) => {
        dispatch( { type: 'setBottler', payload: bottler } );
    },[]);

    const setCustomer = useCallback((customer: Customer | object) => {
        dispatch( { type: 'setCustomer', payload: customer } );
    },[]);

    const setTransaction = useCallback((transaction: Transaction | object) => {
        dispatch( { type: 'setTransaction', payload: transaction } );
    },[]);

    const setConsumerResponse = useCallback((consumerResponse: ConsumerResponse | object) => {
        dispatch( { type: 'setConsumerResponse', payload: consumerResponse } );
    },[]);

    const setError = useCallback((error: object) => {
        dispatch( { type: 'setError', payload: error } );
    },[]);

    const setRedirectOn = useCallback((redirectOn: object) => {
        dispatch( { type: 'setRedirectOn', payload: redirectOn } );
    },[]);

    const setToken = useCallback((token: string) => {
        dispatch( { type: 'setToken', payload: token } );
    },[]);

    const setStatusMercadoPago = useCallback((status: boolean) => {
        dispatch( { type: 'setStatusMercadoPago', payload: status } );
    },[]);

    const setLoading = useCallback((loading: boolean) => {
        dispatch( { type: 'setLoading', payload: loading } );
    },[]);

    return (
        <WrapperContext.Provider value={{
            wrapperState,
            setBottler,
            setCustomer,
            setTransaction,
            setConsumerResponse,
            setError,
            setRedirectOn,
            setToken,
            setStatusMercadoPago,
            setLoading
        }}>
            { children }
        </WrapperContext.Provider>
    );
};
