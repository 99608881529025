import { api } from "./configs/axiosConfig";
import * as jose from 'jose';
import { logger } from "../utils/logger";

const apiLayerUrl: string = process.env.REACT_APP_API_LAYER_URL || '';
const audience: string = process.env.REACT_APP_JWT_AUDIENCE || '';
const issuers: string = process.env.REACT_APP_JWT_ISSUERS || '';
const alg: string = process.env.REACT_APP_JWT_ALG || '';
const jwtType: string = process.env.REACT_APP_JWT_TYPE || '';

export const WrapperLayerAPI = {
    createCustomer: async (email: string): Promise<any> => {
        try {
            const response = await api('api').request({
                url: `${apiLayerUrl}/api/V1/mp/customers`,
                method: "POST",
                data: {
                    'email': email
                }
            });
            logger.info('Customer created successfully:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in createCustomer:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in createCustomer:', JSON.stringify(error));
                throw error;
            }
        }
    },
    getUserCards: async (userId: string): Promise<any> => {
        try {
            const response = await api('api').request({
                url: `${apiLayerUrl}/api/V1/mp/customers/${userId}/cards`,
                method: "GET"
            });
            logger.info('Retrieved user cards successfully:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in getUserCards:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in getUserCards:', JSON.stringify(error));
                throw error;
            }
        }
    },
    saveUserCard: async (userId: string, cardToken: string): Promise<any> => {
        try {
            const response = await api('api').request({
                url: `${apiLayerUrl}/api/V1/mp/customers/${userId}/cards`,
                method: "POST",
                data: {
                    'token': cardToken
                }
            });
            logger.info('User card saved successfully:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in saveUserCard:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in saveUserCard:', JSON.stringify(error));
                throw error;
            }
        }
    },
    getPaymentDetails: async (paymentId: string, accessToken?: string|null|undefined): Promise<any> => {
        try {
            const response = await api('mp', accessToken).request({
                url: `${apiLayerUrl}/api/V1/mp/payments/${paymentId}`,
                method: "GET"
            });
            logger.info('Retrieved payment details successfully:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in getPaymentDetails:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in getPaymentDetails:', JSON.stringify(error));
                throw error;
            }
        }
    },
    savePayment: async ({ paymentData }: { paymentData: any }): Promise<any> => {
        try {
            const response = await api('api').request({
                url: `${apiLayerUrl}/api/V1/mp/payments`,
                method: "POST",
                data: paymentData.formData
            });
            logger.info('Payment saved successfully:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in savePayment:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in savePayment:', JSON.stringify(error));
                throw error;
            }
        }
    },
    getPgBottler: async (bottlerId: string|null): Promise<any> => {
        try {
            const response = await api('api').request({
                url: `${apiLayerUrl}/api/V1/pgbottlers/${bottlerId}`,
                method: "GET"
            });
            logger.info('Retrieved PG bottler successfully:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in getPgBottler:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in getPgBottler:', JSON.stringify(error));
                throw error;
            }
        }
    },
    tokenizeResponse: async (params: object): Promise<any> => {
        try {
            const response = await api('api').request({
                url: `${apiLayerUrl}/api/V1/tokenize`,
                method: "POST",
                data: params
            });
            logger.info('Tokenization successful:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in tokenizeResponse:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in tokenizeResponse:', JSON.stringify(error));
                throw error;
            }
        }
    },
    preparePayment: async (params: object): Promise<any> => {
        try {
            const response = await api('api').request({
                url: `${apiLayerUrl}/api/V1/mp/preferences`,
                method: "POST",
                data: params
            });
            logger.info('Payment preparation successful:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in preparePayment:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in preparePayment:', JSON.stringify(error));
                throw error;
            }
        }
    },
    verifyLegitToken: async (jwt: string): Promise<any> => {
        try {
            const decodedHeader = jose.decodeProtectedHeader(jwt);
            if (decodedHeader.alg !== alg) {
                throw new Error("Invalid Algorithm")
            } else if (decodedHeader.typ !== jwtType) {
                throw new Error("Invalid Type")
            }
    
            const decodedJwt = jose.decodeJwt(jwt);
    
            const url = decodedJwt.iss ?? '';
            const isExpired = decodedJwt.exp !== undefined ? decodedJwt.exp < (new Date().getTime() + 1) / 1000 : false;
            const validDomains = issuers ?? '';
            let validIss = '';
            for (const issuer of validDomains.split(',')) {
                if (url.includes(issuer)) {
                    validIss = url
                }
            }
            if (validIss === '') {
                throw new Error("Domain is not allowed")
            } else if (isExpired) {
                throw new Error("JWT is expired")
            }
            const jwkSet = jose.createRemoteJWKSet(new URL(url))
            const { payload, protectedHeader } = await jose.jwtVerify(jwt, jwkSet, {
                issuer: validIss,
                audience: audience,
            });
            logger.info('Token verification successful:', payload);
            return {
                protectedHeader: protectedHeader,
                payload: payload
            };
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in verifyLegitToken:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in verifyLegitToken:', JSON.stringify(error));
                throw error;
            }
        }
    },    
    validatePayment: async (params: object, accessToken?: string|null|undefined): Promise<any> => {
        try {
            const response = await api('api').request({
                url: `${apiLayerUrl}/api/V1/mp/payments/validate`,
                method: "POST",
                data: params
            });
            logger.info('Payment validation successful:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in validatePayment:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in validatePayment:', JSON.stringify(error));
                throw error;
            }
        }
    },
    updatePayment: async (params: object, accessToken?: string|null|undefined): Promise<any> => {
        try {
            const response = await api('api').request({
                url: `${apiLayerUrl}/api/V1/mp/payments/update`,
                method: "POST",
                data: params
            });
            logger.info('Payment update successful:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in updatePayment:', error.message);
                throw error;
            } else {
                logger.error('Unknown error in updatePayment:', JSON.stringify(error));
                throw error;
            }
        }
    },
    initialize: async (transactionID: string): Promise<any> => {
        try {
            const response = await api('api').request({
                url: `${apiLayerUrl}/api/V1/initialize/${transactionID}`,
                method: 'GET'
            });
            logger.info('Initialization successful:', response.data);
            return response;
        } catch (error: any) {
            if (error instanceof Error) {
                logger.error('Error in initialize:', error.message);
                logger.info("X-Operation-Id:", logger.operationId);
                throw error;
            } else {
                logger.error('Unknown error in initialize:', JSON.stringify(error));
                throw error;
            }
        }
    }
}
