import {useContext} from "react";
import {WrapperContext} from "../context/WrapperContext";

export const SaveCardMessage= () => {
    const {wrapperState} = useContext(WrapperContext);
    const {transaction: {payment}, customer} = wrapperState;

   
    return (
        <>
            {
                (!customer.cardsIds?.includes(payment?.card?.id as string)) ?
                    (!customer.saved_card.status) ?
                        <div className="content-savemessage">
                            <p>¿Deseas Guardar Tarjeta?</p>
                        </div>
                        : ''
                    : ''
            }
        </>
    );
};
