import {api} from "./configs/axiosConfig"
import { logger } from "../utils/logger";

const apiLayerUrl = process.env.REACT_APP_API_LAYER_URL
export const CdsAPI = {
    getIdPayer: async (accessToken?: string|null|undefined) => {
        return await api('cds', accessToken).request({
            url: apiLayerUrl + '/api/V1/cds/consumers/enrichment-data',
            method: "GET"
        }).catch(error => {
            logger.error('Error in getIdPayer:', error);
        })
    },
    saveIdPayer: async (idPayer: string, accessToken?: string|null|undefined) => {
        return await api('cds', accessToken).request({
            url: apiLayerUrl + '/api/V1/cds/consumers/enrichment-data',
            method: "POST",
            data: {
                "id_payer_mp": idPayer
            }
        }).catch(error => {
            logger.error('Error in saveIdPayer:', error);
        })
    },
}
