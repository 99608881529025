import {useContext} from "react";
import {WrapperContext} from "../context/WrapperContext";
import {useRedirect} from '../hooks/useRedirect';

export const GoBackButton = () => {
    const {wrapperState} = useContext(WrapperContext);
    const {transaction, consumerResponse, redirectOn} = wrapperState;
    const redirectUrl = consumerResponse.redirect_url;
    const btnText = consumerResponse.transaction_status === undefined ? 'Regresar' : 'Regresar al sitio';
    const {handleReturn} = useRedirect();

    return (
        <>
            {
                redirectUrl ?
                    <button className="btn btn-primary go-back" onClick={handleReturn} disabled={redirectOn.status}>
                        <div className="icon back-icon"></div>
                        { (transaction.payment?.status === 'approved') ? 'Finalizar pedido': btnText}
                    </button>
                    : ''
            }
        </>
    );
};
