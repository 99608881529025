import {LoginForm} from "../components/LoginForm";
import {Navigate, Route, Routes} from "react-router-dom";
import {BackStatus} from "../components/BackStatus";

export const WrapperRouter = () => {
    return (
        <Routes>
            <Route path="/" element={
                <LoginForm/>
            } />

            <Route path="/login-back" element={
                <BackStatus />
            } />

            <Route path="/*" element={<Navigate to="/" />} />
        </Routes>
    );
}