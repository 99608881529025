import {useCallback, useContext} from "react";
import {WrapperLayerAPI} from "../apis/WrapperLayerAPI";
import {CdsAPI} from "../apis/CdsAPI";
import {WrapperContext} from "../context/WrapperContext";
import {WrapperErrorMessages} from "../errors/WrapperErrorMessages";
import * as jose from 'jose';
import { logger } from "../utils/logger";

export const useLoginForm = () => {
    const {setBottler, setError, setCustomer, setTransaction, setConsumerResponse, setRedirectOn, setLoading} = useContext(WrapperContext);

    const setInitValues = useCallback( (request: any) => {
        setCustomer({token_cds: request.cds_token});
        setTransaction({total_amount: request.amount, reference_id: request.reference_id});
        setBottler({bottler_id: request.bottler_id});
    }, [setCustomer, setTransaction, setBottler]);
    const isValidUrl = (urlString: string) => {
        try {
            return Boolean(new URL(urlString));
        } catch(e) {
            return false;
        }
    }

    const checkPayload = useCallback((request: any) => {
        const requiredFields = ['amount', 'bottler_id', 'redirect_url', 'cds_token', 'reference_id'];
        let error = '';
        requiredFields.forEach(function (requiredField) {
            if (!request[requiredField] || request[requiredField] === null || request[requiredField] === '') {
                error = "invalid payload, required field: "+requiredField;
            }
            switch (requiredField) {
                case 'amount':
                    error = (isNaN(request[requiredField])) ? "invalid payload, amount field must be a number" : error;
                    break;
                case 'redirect_url':
                    error = (!isValidUrl(request[requiredField])) ? "invalid payload, redirect_url field must be a valid url" : error;
                    break;
                default:
                    break;
            }
        })
        return error;
    }, []);

    const initLogin = useCallback(async (transactionId: string, checkExpiration = true) => {
        if (transactionId) {
            let jwtInitial = transactionId;
            if(!transactionId.includes('eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9')) {
                try {
                    const initialize = await WrapperLayerAPI.initialize(transactionId);
                    jwtInitial = initialize?.data?.message;
                } catch (error: any)  {
                    logger.error('Error in initialize:', error);
                    setError(WrapperErrorMessages.WRAPPER_GENERIC);
                    return;
                }
            }            
            localStorage.setItem('token', transactionId);
            WrapperLayerAPI.verifyLegitToken(jwtInitial).then(response => {
                if (response?.payload) {
                    const request = response.payload,
                          error = checkPayload(request);
                    let processId: string | null = null;

                    if (request?.process_id !== undefined && request?.process_id !== null) {
                        setConsumerResponse({process_id: request?.process_id});
                        processId = request?.process_id as string;
                    }

                    if (request.iss !== null && request.redirect_url !== null) {
                        const url = new URL(request.iss as string);
                        const path = url.protocol+'//'+url.host;
                        setConsumerResponse({
                            aud: path,
                            redirect_url: request.redirect_url
                        });
                    }

                    if (error) {
                        logger.error(error);
                        setError(WrapperErrorMessages.WRAPPER_GENERIC);
                        return;
                    }

                    const decodedToken = jose.decodeJwt(request.cds_token as string);
                    const isExpired = decodedToken.exp !== undefined ? decodedToken.exp < (new Date().getTime() + 1) / 1000: false;
                    setInitValues(request);

                    const customerEmail = (decodedToken?.preferred_username) ? decodedToken.preferred_username : '';
                    setCustomer({email: customerEmail});

                    if(isExpired && checkExpiration){
                        logger.error(WrapperErrorMessages.EXPIRED_TOKEN.message);
                        setError(WrapperErrorMessages.WRAPPER_GENERIC);
                        return;
                    }

                    if (request.bottler_id !== null) {
                        WrapperLayerAPI.getPgBottler(request.bottler_id as string).then((response) => {
                            if (response?.data) {
                                const bottler = {...response.data, payment_ga_config: JSON.parse(response.data.payment_ga_config)}
                                let id_payer: string;
                                CdsAPI.getIdPayer(request.cds_token as string).then((response) => {
                                    if (response !== undefined && response.hasOwnProperty('status') && response.status !== 404) {
                                        id_payer = response.data?.id_payer_mp;
                                        setCustomer({enrichment_data: response.data});
                                    }
                                    return  WrapperLayerAPI.validatePayment({
                                        process_id: processId,
                                        reference_id: request.reference_id,
                                        amount: request.amount
                                    }, response?.data?.payment_ga_config?.access_token);
                                }).then((response) => {                        
                                    if (response !== undefined && response.hasOwnProperty('status') && response.status === 200) {            
                                        if (response?.data && response?.data?.ok) {
                                            const status = response?.data?.message?.transaction_status?.toLowerCase();
                                            setConsumerResponse({process_id: response?.data?.message?.transaction_id});
                                            if(status === 'approved'){
                                                const data = response?.data?.message;
                                                const dataPayment = data?.payment_transaction;
                                                setTransaction({payment: dataPayment?.payment_id});
                                                setConsumerResponse({
                                                    transaction_id: dataPayment?.payment_id,
                                                    transaction_amount: dataPayment?.total_amount,
                                                    transaction_status: status,
                                                    payer_id: id_payer,
                                                    payer_email: customerEmail
                                                });
                                                setError({status: true, message: "El pago de tu orden ya fue aprobado.", retry: false, redirect: true});
                                                setRedirectOn({status: false, payment_status: true})
                                            }
                                        }
                                    }
                                }).finally(()=> {
                                    setBottler(bottler);
                                    setLoading(false);
                                });
                            } else {
                                logger.error(WrapperErrorMessages.PG_BOTTLER_API.message);
                                setError(WrapperErrorMessages.WRAPPER_GENERIC);
                            }
                        }).catch(error => {
                            logger.error(error);
                            setError(WrapperErrorMessages.WRAPPER_GENERIC);
                        });
                    }
                }
            }).catch(error => {
                logger.error(error);
                setError(WrapperErrorMessages.WRAPPER_GENERIC);
            })
        } else {
            setError(WrapperErrorMessages.WRAPPER_GENERIC);
        }
    }, [setInitValues, setBottler, setCustomer, setError, setConsumerResponse, checkPayload, setRedirectOn, setTransaction, setLoading]);


    return {
        initLogin,
    };
};
