import {createContext} from "react";
import {Bottler, ConsumerResponse, Customer, Transaction, WrapperState, Redirect} from "../models/WrapperStateModel";
export type WrapperContextProps = {
    wrapperState: WrapperState,
    setBottler: (bottler: Bottler  | object) => void;
    setCustomer: (customer: Customer | object) => void;
    setTransaction: (transaction: Transaction | object) => void;
    setConsumerResponse: (consumerResponse: ConsumerResponse | object) => void;
    setError: (value: object) => void;
    setRedirectOn: (redirectOn: Redirect | object) => void;
    setToken: (value: string) => void;
    setStatusMercadoPago: (value: boolean) => void;
    setLoading: (value: boolean) => void;
}
export const WrapperContext = createContext<WrapperContextProps>({} as WrapperContextProps);
