import {Bottler, ConsumerResponse, Customer, Transaction, WrapperState, Redirect} from "../models/WrapperStateModel";

type WrapperAction =
    | { type: 'setBottler', payload: Bottler }
    | { type: 'setCustomer', payload: Customer | object }
    | { type: 'setTransaction', payload: Transaction | object }
    | { type: 'setConsumerResponse', payload: ConsumerResponse | object }
    | { type: 'setError', payload: object }
    | { type: 'setRedirectOn', payload: Redirect | object }
    | { type: 'setToken', payload: string }
    | { type: 'setStatusMercadoPago', payload: boolean }
    | { type: 'setLoading', payload: boolean };

export const wrapperReducer = (state: WrapperState, action: WrapperAction): WrapperState => {
    switch (action.type) {
        case 'setBottler':
            return {
                ...state,
                bottler: {...state.bottler, ...action.payload}
            }
        case 'setError':
            return {
                ...state,
                error: {...state.error, ...action.payload}
            }
        case 'setCustomer':
            return {
                ...state,
                customer: {...state.customer, ...action.payload}
            }
        case 'setTransaction':
            return {
                ...state,
                transaction: {...state.transaction, ...action.payload}
            }
        case 'setConsumerResponse':
            return {
                ...state,
                consumerResponse: {...state.consumerResponse, ...action.payload}
            }
        case 'setRedirectOn':
            return {
                ...state,
                redirectOn: {...state.redirectOn, ...action.payload}
            }
        case 'setToken':
            return {
                ...state,
                token: action.payload
            }
        case 'setStatusMercadoPago':
            return {
                ...state,
                statusM: action.payload
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
};
