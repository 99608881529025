import {useContext} from "react";
import {WrapperContext} from "../context/WrapperContext";

export const PaymentResultsSection = () => {

    const {wrapperState} = useContext(WrapperContext);
    const {transaction: {payment}} = wrapperState;

    const paymentDate = new Date(`${payment?.date_approved}`);
    const formattedDate = paymentDate?.toLocaleString('es-MX', { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit',  hour12: false});

    return (
        <>
            {
                ( payment?.status === 'approved' && payment?.date_approved) ?
                    <section className="results-info">
                        <div className="results-layout">
                            <div className="transaction-details">
                                <div role="img" className="transaction-details-icon">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.40039 5.795H13.6004V4.595H6.40039V5.795Z" fill="black" fillOpacity="0.9"></path><path d="M10.0004 8.79721H6.40039V7.59721H10.0004V8.79721Z" fill="black" fillOpacity="0.9"></path><path fillRule="evenodd" clipRule="evenodd" d="M10.0346 19.3369L8.00499 16.9324L6.00178 19.3245L3.40039 16.218V1H16.6004V16.2173L14.0083 19.3257L12.0097 16.9391L10.0346 19.3369ZM15.4004 2.2H4.60039V15.782L6.00178 17.4555L8.00135 15.0676L10.0233 17.4631L12.0022 15.0609L14.0064 17.4543L15.4004 15.7827V2.2Z" fill="black" fillOpacity="0.9"></path></svg>
                                </div>
                                <div className="details-container">
                                    <p className="title">
                                        Operación # {payment?.id}
                                    </p>
                                    <span className="subtitle">{`${formattedDate}`} hrs</span>
                                </div>
                            </div>
                        </div>
                    </section>
                : payment?.status_message ?
                    <div className="payment-status-layout">
                        <p className="payment-status-message">
                            {payment?.status_message}
                        </p>
                    </div>
                        : ''
            }
        </>
    );
};
