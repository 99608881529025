import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { logger, LogLevel } from './utils/logger';
import generateID from './utils/generateId';
import getQueryString from './utils/getQueryString';

const traceId = getQueryString('traceId');
const operationId = traceId || generateID(8);

const logLevel = process.env.LOG_LEVEL || 'INFO';
const logLevelValue = LogLevel[logLevel as keyof typeof LogLevel];

logger.setLevel(logLevelValue);
logger.setOperationId(operationId);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
