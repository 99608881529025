export const WrapperErrorMessages = {
    EXPIRED_TOKEN: {
        status: true,
        message: 'Tu sesión ha expirado',
    },
    CDS_API: {
        status: true,
        message: 'No se han podido obtener datos del usuario',
    },
    PG_BOTTLER_API: {
        status: true,
        message: 'No se han podido obtener datos de pago',
    },
    SAVE_PAYMENT: {
        status: true,
        message: 'No se ha podido realizar el pago, inténtelo de nuevo',
    },
    BRICKS_GENERIC: {
        status: true,
        message: 'Ha ocurrido un error, inténtelo de nuevo',
    },
    MISSING_PARAMS: {
        status: true,
        message: 'No se han enviado los parámetros correctos',
    },
    WRAPPER_GENERIC: {
        status: true,
        message: 'Lo sentimos, pero no podemos procesar tu solicitud. Favor de regresar a en tu hogar by Coca-Cola para intentarlo de nuevo o selecciona otro método de pago.',
    },
    RETRY_GENERIC: {
        status: true,
        message: 'Lo sentimos, no pudimos procesar tu solicitud. Inténtalo de nuevo o regresa a en tu hogar by Coca-Cola para seleccionar otro método de pago.',
    },
    BROWSER_VERSION: {
        status: true,
        message: 'No podemos procesar tu pago en línea, actualiza tu navegador a la versión más reciente.',
    },
    APPROVED_PAYMENT: {
        status: true,
        message: 'El pago de tu orden ya fue aprobado.',
    }
}