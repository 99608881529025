import axios from 'axios';
import {logger} from '../../utils/logger'

const apiLayerKey = process.env.REACT_APP_API_LAYER_X_API_KEY;

export const api = (type?: string, accessToken?: string | null | undefined) => {
  let token: string | undefined | null = '';
  let headers: {
    'Content-Type': string;
    Accept: string;
    Authorization?: string;
    'x-api-key'?: string;
    'X-Operation-Id'?: string;
  } = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  if (type !== undefined) {
    if (type === 'api') {
      headers['x-api-key'] = apiLayerKey;
      headers['X-Operation-Id'] = logger.operationId !== null ? logger.operationId : undefined;
    } else {
      if (type === 'cds') {
        token = accessToken;
      } else if (type === 'mp') {
        token = accessToken;
      }
      headers.Authorization = 'Bearer ' + token;
    }
  }
  return axios.create({
    headers: headers,
  });
};
