import {useCallback} from "react";
import Bowser from "bowser"; 

export const useBrowserVersion = () => {

    const checkBrowser = useCallback(() => {
        const browser = Bowser.getParser(window.navigator.userAgent);

        const isValidBrowser = browser.satisfies({
            mobile: {
                safari: '>13.3',
                firefox: '>74',
                chrome: '>80',
                'android browser': '>0'
            },
            desktop: {
                chrome: '>80',
                firefox: '>74',
                safari: '>14',
                opera: '>0',
                edge:'>80',
                'internet explorer': '=11'
            },
            tablet: {
                safari: '>13.3',
                firefox: '>74',
                chrome: '>80',
                'android browser': '>0'
            },
        }) || false;
        
        return isValidBrowser;
    }, []);

    return {
        checkBrowser,
    };
};
