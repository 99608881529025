import {useContext} from "react";
import {WrapperContext} from "../context/WrapperContext";
import {useState, useEffect} from 'react';
import {useRedirect} from '../hooks/useRedirect';


export const GoBackRedirect = () => {
    const {wrapperState} = useContext(WrapperContext);
    const {customer, consumerResponse, error, redirectOn} = wrapperState;
    const redirectUrl = consumerResponse.redirect_url;
    const {handleReturn} = useRedirect();

    const defaultSeconds = 10;
    const defaultSaveCard = 5;

    const [seconds, setSeconds] = useState(defaultSeconds); // 5 seconds

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (seconds > 0) {
                setSeconds(prevCounter => prevCounter - 1);
            } else {
                handleReturn();
            }
        }, 1000);
    
        return () => clearTimeout(timerId);
    }, [seconds, handleReturn]);

    useEffect(() => {
        if(customer.saved_card.is_loading && seconds < 4)
        {
            setSeconds((prevSeconds) => prevSeconds + defaultSaveCard);
        }
    }, [customer.saved_card.is_loading, seconds]);

    return (
        <>
            {
                redirectUrl ?
                    <div className="content-message">
                        {
                             (error?.redirect && !redirectOn?.payment_status) ? 
                                <p >Serás regresado al sitio automáticamente en {seconds} segundos.</p>                               
                             :
                             <p >Tu pedido será finalizado automáticamente en {seconds} segundos.</p>    
                        }
                       
                    </div>
                : ''
            }
        </>
    );
};
