import {WrapperProvider} from "./context/WrapperProvider";
import {WrapperRouter} from "./router/WrapperRouter";
import {BrowserRouter} from "react-router-dom";

export const App = () => {
    return (
        <WrapperProvider>
            <BrowserRouter>
                <WrapperRouter/>
            </BrowserRouter>
        </WrapperProvider>
    );
}
