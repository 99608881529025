import {useContext, useEffect} from "react";
import {WrapperContext} from "../context/WrapperContext";
import {GoBackButton} from "../components/GoBackButton";
import {RetryButton} from "../components/RetryButton";
import {GoBackRedirect} from "../components/GoBackRedirect";


export const WrapperError = () => {
    const {setLoading, wrapperState: {error, bottler}} = useContext(WrapperContext);

    useEffect(() => {
        if(error.status){
            setLoading (false);
        }
    }, [error.status, setLoading]);

    return (
        <>
            {
                (error.status) ?
                    <div className="error-layout">
                        <h2>{error.message}</h2>
                        <div className="button-layout">
                            {
                                (error.retry) ?
                                    <RetryButton/>
                                    : ''
                            }
                            {
                                (error.redirect) ?
                                   (bottler.payment_ga_id)?
                                        <div className="container_error">
                                            <GoBackRedirect/>
                                            <GoBackButton/>
                                        </div>
                                    : ''
                                : <GoBackButton/>
                            }
                        </div>
                    </div>
                    : ''
            }
        </>
    );
};
