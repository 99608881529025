export const RetryButton = () => {
    const handleRetry = () => {
        window.location.reload();
    };

    return (
        <>
            <button className="btn btn-primary retry" onClick={handleRetry}>
                <div className="icon retry-icon"></div>
                Reintentar
            </button>
        </>
    );
};
