import {useContext, useEffect, useRef} from "react";
import {WrapperContext} from "../context/WrapperContext";
import {useMercadoPago} from "../hooks/useMercadoPago";
import {Header} from "../components/Header";
import {SaveCardButton} from "../components/SaveCardButton";
import "../styles/mercadoPago.css"
import {GoBackButton} from "../components/GoBackButton";
import {PaymentResultsSection} from "../components/PaymentResultsSection";
import {GoBackRedirect} from "../components/GoBackRedirect";
import {SaveCardMessage} from "../components/SaveCardMessage";
import {useRedirect} from "../hooks/useRedirect";

export const MercadoPagoComponent = () => {

    const {wrapperState} = useContext(WrapperContext);
    const {transaction: {payment}, statusM, token} = wrapperState;
    const {initMP} = useMercadoPago();
    const {generateToken} = useRedirect();
    const hasExecuted = useRef(false);

    useEffect(() => {
        initMP();
    }, [initMP]);

    useEffect(() => {
        if (statusM && !hasExecuted.current) {
            generateToken();
            hasExecuted.current = true; // Establecer la referencia en true después de ejecutar generateToken
        }
    }, [statusM, generateToken]);

    return (
        <main>
            <Header />
            <section className="payment-form">
                <div className="container__payment">
                    <div id="paymentBrick_container" className="form-payment" />

                    <div className="button-layout">
                        <GoBackButton />
                    </div>
                </div>
            </section>
            <section className="payment-form">
                <div className="container container__result hidden">
                    <div className="content">
                        <div id="statusScreenBrick_container"/>
                        <PaymentResultsSection />
                    </div>

                    <div className="button-layout">
                        {
                            ( payment?.status === 'approved') ? <SaveCardMessage /> : ''
                        }
                    </div>

                    <div className="button-layout ">
                        {
                            ( payment?.status === 'approved' && token) ? <GoBackRedirect /> : ''
                        }
                    </div>

                    <div className="button-layout">
                        <GoBackButton />
                        {
                            ( payment?.status === 'approved') ? <SaveCardButton /> : ''
                        }
                    </div>
                </div>
            </section>
        </main>

    );
};
