import {useCallback, useContext} from "react";
import {WrapperContext} from "../context/WrapperContext";
import {WrapperLayerAPI} from "../apis/WrapperLayerAPI";
import {CdsAPI} from "../apis/CdsAPI";

export const SaveCardButton = () => {
    const {wrapperState, setCustomer, setConsumerResponse} = useContext(WrapperContext);
    const {transaction: {success_card, payment}, customer} = wrapperState;

    const handleSaveCard = useCallback(async () => {
        setCustomer({saved_card: { is_loading: true }})
        let customerId = customer.enrichment_data?.id_payer_mp;
        if (!customerId) {
            const resp = await WrapperLayerAPI.createCustomer(customer.email);
            customerId = resp?.data.id;

            if (customerId) {
                setConsumerResponse({payer_id: customerId})
                await CdsAPI.saveIdPayer(customerId, customer.token_cds);
            } else {
                setCustomer({
                    save_card_error: true,
                    saved_card: {is_loading: false, error_message: 'Failed on save customer'}
                });
                return;
            }
        }

        const cardToken = success_card?.formData?.token;
        if (customerId && cardToken) {
            const savedCard = await WrapperLayerAPI.saveUserCard(customerId, cardToken);
            setCustomer({saved_card: { status: true }})

            if (!savedCard?.data?.id) {
                setCustomer({saved_card: { is_loading: false, status: false, error_message: 'Card couldn\'t be saved' }})
            }
        } else {
            setCustomer({saved_card: { is_loading: false, status: false, error_message: 'Card couldn\'t be saved' }})
        }
    },[setCustomer, setConsumerResponse, customer.email, customer.token_cds, success_card?.formData?.token, customer.enrichment_data?.id_payer_mp]);
    
    return (
        <>
            {
                (!customer.cardsIds?.includes(payment?.card?.id as string)) ?
                    (!customer.saved_card.error_message) ?
                        <>
                            <button id="save-card"
                                    className={`btn btn-success save-card ${(customer.saved_card.status) ? 'hidden' : ''}`}
                                    onClick={handleSaveCard} disabled={customer.saved_card.is_loading}>
                                {
                                    customer.saved_card.is_loading ?
                                        <div className="icon loading-spinner"></div>
                                        :
                                        <>
                                            <div className="icon save-icon"></div>
                                            <span>Guardar tarjeta</span>
                                        </>
                                }
                            </button>
                            <span className={`saved-card ${(!customer.saved_card.status) ? 'hidden' : ''}`}><div
                                className="icon check-icon"></div>¡Tarjeta guardada!</span>
                        </>
                        : <span className="saved-card-error"><div className="icon cross-icon"></div>Tu tarjeta no pudo ser guardada</span>
                    : ''
            }
        </>
    );
};
