import {useCallback, useContext} from "react";
import {WrapperLayerAPI} from "../apis/WrapperLayerAPI";
import {WrapperContext} from "../context/WrapperContext";
import {WrapperErrorMessages} from "../errors/WrapperErrorMessages";
import {MercadoPagoMessages} from "../errors/MercadoPagoErrors";
import {BricksException} from "../errors/BricksException";
import {MercadoPagoItems}  from "../parameters/MercadoPagoItems";
import { logger } from "../utils/logger";

export const useMercadoPago = () => {

    const {wrapperState, setError, setTransaction, setCustomer, setConsumerResponse, setStatusMercadoPago, setRedirectOn} = useContext(WrapperContext)
    const {bottler, customer, transaction, consumerResponse} = wrapperState;

    const renderStatusScreenBrick = useCallback(async (bricksBuilder: { create: (arg0: string, arg1: string, arg2: { initialization: { amount: number }; customization: { visual: { style: { theme: string } }; paymentMethods: { minInstallments: number; maxInstallments: number } }; callbacks: { onReady: () => void; onSubmit: (cardFormData: any) => Promise<unknown>; onError: (error: any) => void } }) => any }, payment: any) => {
        const settings = {
            initialization: {
                paymentId: payment?.id,
            },
            customization: {
                visual: {
                    hideStatusDetails: true,
                    style: {
                        theme: 'default'
                    }
                },
                backUrls: {
                    error: window.location.href,
                }
            },
            callbacks: {
                onReady: () => {
                },
                onError: () => {
                },
            },
        };
        // @ts-ignore
        window.statusBrickController = await bricksBuilder.create('statusScreen', 'statusScreenBrick_container', settings);
    }, []);

    const renderCardPaymentBrick = useCallback(async (
        bricksBuilder: {
            create: (
                arg0: string,
                arg1: string,
                arg2: {
                    initialization: {
                        amount: number
                    };
                    customization: {
                        visual: {
                            style: {
                                theme: string
                            }
                        };
                        paymentMethods: {
                            minInstallments: number;
                            maxInstallments: number
                        }
                    };
                    callbacks: {
                        onReady: () => void;
                        onSubmit: (cardFormData: any) => Promise<unknown>;
                        onError: (error: any) => void
                    }
                }) => any
        },
        amount: string | null | undefined,
        referenceId: string | null | undefined,
        user: any,
        bottler: any,
        cardsIds: any,
        preferenceId: any) => {
        const settings = {
            initialization: {
                amount: amount,
                preferenceId: preferenceId,
                payer: {
                    email: customer.email,
                    customerId: customer.enrichment_data?.id_payer_mp ?? '',
                    cardsIds: cardsIds ?? []
                }
            },
            customization: {
                visual: {
                    style: {
                        theme: 'default',
                    },
                    texts: {
                        formTitle: "¿Cómo quieres pagar?",
                        paymentMethods: {
                            newCreditCardTitle: "Agregar nueva tarjeta de crédito",
                            newDebitCardTitle: "Agregar nueva tarjeta de débito",
                        }
                    }
                },
                paymentMethods: bottler.payment_ga_config.valid_methods
            },
            callbacks: {
                onReady: () => {
                    /*Callback*/
                },
                onSubmit: async (cardFormData: any) => {
                    // TODO: default description, needs to be custom per order?
                    cardFormData.formData.bottler_id = bottler.bottler_id;
                    cardFormData.formData.description = "en tu hogar by Coca-Cola";
                    cardFormData.formData.binary_mode = true;
                    cardFormData.formData.external_reference = referenceId;
                    cardFormData.formData.additional_info = {"items": [{"id": referenceId,
                                                                        "title": `${MercadoPagoItems.TITLE} ${referenceId}`,
                                                                        "description":`${MercadoPagoItems.DESCRIPTION} ${referenceId}`,
                                                                        "quantity": MercadoPagoItems.QUANTITY,
                                                                        "unit_price": cardFormData.formData.transaction_amount
                                                                      }]
                                                            };                    
                    return new Promise<void>(async (resolve, reject) => {
                        let prePaymentStatus: string = "";

                        const prePaymentResponse = await WrapperLayerAPI.validatePayment({
                            process_id: consumerResponse.process_id,
                            reference_id: cardFormData.formData.external_reference,
                            amount: cardFormData.formData.transaction_amount
                        }, bottler.payment_ga_config.access_tokenn);
                        
                        if (prePaymentResponse !== undefined && prePaymentResponse.hasOwnProperty('status') && prePaymentResponse.status === 200) {            
                            if (prePaymentResponse?.data && prePaymentResponse?.data?.ok) {
                                prePaymentStatus = prePaymentResponse?.data?.message?.transaction_status?.toLowerCase();
                                setConsumerResponse({process_id: prePaymentResponse?.data?.message?.transaction_id});
                                if(prePaymentStatus === 'approved'){
                                    const data = prePaymentResponse?.data?.message;
                                    const dataPayment = data?.payment_transaction;
                                    setTransaction({payment: dataPayment?.payment_id});
                                    setConsumerResponse({
                                        transaction_id: dataPayment?.payment_id
                                    });
                                    setError({status: true, message: "El pago de tu orden ya fue aprobado.", retry: false, redirect: true});
                                    setRedirectOn({status: false, payment_status: true})
                                    return data;
                                }
                            }
                        }

                        cardFormData.formData.transaction_id = consumerResponse.process_id;
                        
                        const paymentResponse = await WrapperLayerAPI.savePayment({paymentData: cardFormData});
                        if (paymentResponse) {
                            const data = paymentResponse?.data;
                            if (!data.hasOwnProperty("error_message")) {
                                const paymentContainer = document.querySelector('.container__payment');
                                paymentContainer?.classList.add('fadeOut')
                                paymentContainer?.classList.add('hidden')
                                setTimeout(() => {
                                        const result = document.querySelector('.container__result');
                                        result?.classList.remove('hidden')
                                        result?.classList.add('fadeIn')
                                    }, 500
                                );
                            }
                            for (let key of Object.keys(MercadoPagoMessages)) {
                                if (key === data.status_detail) {
                                    data.status_message = MercadoPagoMessages[key as keyof typeof MercadoPagoMessages];
                                }
                            }
                            setTransaction({payment: data, success_card: cardFormData});
                            setConsumerResponse({
                                transaction_id: data.id
                            });
                            setStatusMercadoPago(true);
                            // @ts-ignore eslint-disable-next-line
                            const mps = new MercadoPago(bottler.payment_ga_config.public_key, {
                                locale: 'es-MX',
                            })
                            let statusBricksBuilder = mps.bricks();
                            await renderStatusScreenBrick(statusBricksBuilder, data);
                            return data;
                        } else {
                            const content = document.querySelector('main');
                            content?.classList.add('fadeOut')
                            content?.classList.add('hidden')
                            setConsumerResponse({transaction_status: 'error'})
                            setError({status: true, message: MercadoPagoMessages.cc_rejected_other_reason , retry: true});
                            reject();
                        }
                    });
                },
                onError: (error: BricksException) => {
                    const errorMessage = JSON.stringify(error);
                    logger.error(errorMessage);
                    if(error?.type !== 'non_critical') {
                        setConsumerResponse({transaction_status: 'error'});
                        setError(WrapperErrorMessages.BRICKS_GENERIC);
                    }
                },
            },
        };
        // @ts-ignore
        window.cardPaymentBrickController = await bricksBuilder.create('payment', 'paymentBrick_container', settings);
    }, [setError, setTransaction, setConsumerResponse, customer.email, customer.enrichment_data?.id_payer_mp, setStatusMercadoPago, renderStatusScreenBrick, consumerResponse.process_id, setRedirectOn,]);

    const initMP = useCallback(async () => {
        let cardsIds = [] as string[];
        let preferenceId = null;
        if (customer.enrichment_data?.id_payer_mp) {
            const response = await WrapperLayerAPI.getUserCards( customer.enrichment_data.id_payer_mp)
            if (response?.data) {
                let cards = response.data
                cards.forEach((card: { id: any; }) => {
                    cardsIds.push(card.id);
                });
                setCustomer({cardsIds: cardsIds});
            }
        }
        if (bottler.payment_ga_config?.valid_methods.hasOwnProperty('mercadoPago') && transaction.payment?.id === undefined) {
            try {
                const redirect_url = `${window.location.protocol}//${window.location.host}/login-back`;
                const createPreference = await WrapperLayerAPI.preparePayment({
                    'bottler_id': bottler.bottler_id,
                    'amount': transaction.total_amount,
                    'reference_id': transaction.reference_id,
                    redirect_url
                });
                if (createPreference.data) {
                    const preference = createPreference.data;
                    preferenceId = preference.preference_id;
                }
            } catch (error) {
                const errorMessage = String(error);
                logger.error(errorMessage);
            }
        }
        if (transaction.payment?.id === undefined) {
            // @ts-ignore eslint-disable-next-line
            let mp = new MercadoPago(bottler.payment_ga_config.parent_public_key, {
                locale: 'es-MX',
            })
            let bricksBuilder = mp.bricks();

            await renderCardPaymentBrick(bricksBuilder, transaction.total_amount, transaction.reference_id, customer.enrichment_data, bottler, cardsIds, preferenceId);
        }
    }, [setCustomer, bottler, transaction.total_amount, transaction.reference_id, transaction.payment?.id, renderCardPaymentBrick, customer.enrichment_data]);

    return {
        initMP,
        renderStatusScreenBrick
    };
};
