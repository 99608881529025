import {useCallback, useContext, useEffect} from "react";
import {Header} from "./Header";
import {GoBackButton} from "./GoBackButton";
import {PaymentResultsSection} from "./PaymentResultsSection";
import {WrapperContext} from "../context/WrapperContext";
import {WrapperErrorMessages} from "../errors/WrapperErrorMessages";
import {WrapperError} from "../errors/WrapperError";
import {useLoginForm} from "../hooks/useLoginForm";
import {useMercadoPago} from "../hooks/useMercadoPago";

const queryParams = new URLSearchParams(window.location.search)
export const BackStatus = () => {

    const {renderStatusScreenBrick} = useMercadoPago(),
          {initLogin} = useLoginForm(),
          {wrapperState, setError, setTransaction, setConsumerResponse} = useContext(WrapperContext),
          {transaction, customer, bottler} = wrapperState;

    const renderStatus = useCallback(async() => {
        const paymentId = queryParams.get('payment_id'),
              paymentStatus = queryParams.get('status');
        //TODO match reference_id to externalReference = queryParams.get('external_reference') coming from MP response

        const jwt = localStorage.getItem('token');

        if (jwt) { //TODO validate if reference_id in jwt is same as coming back from MP
            initLogin(jwt, false);
            if (paymentId && paymentStatus) {
                setTransaction({payment: {status: paymentStatus, id: paymentId}});
                setConsumerResponse({
                    transaction_id: paymentId,
                    transaction_amount: transaction.total_amount,
                    transaction_status: paymentStatus,
                    payer_id: customer.enrichment_data?.id_payer_mp,
                    payer_email: customer.email
                });

                if (bottler.payment_ga_config?.public_key) {
                    // @ts-ignore eslint-disable-next-line
                    const mp = new MercadoPago(bottler.payment_ga_config.public_key ?? publicKey, {
                        locale: 'es-MX',
                    })
                    const bricksBuilder = mp.bricks();
                    if (bricksBuilder) {
                        renderStatusScreenBrick(bricksBuilder, {status: paymentStatus, id: paymentId});
                    }
                }
            } else {
                setConsumerResponse({transaction_status: 'error'})
                setError(WrapperErrorMessages.WRAPPER_GENERIC);
            }
        } else {
            setConsumerResponse({transaction_id: paymentId, transaction_status: paymentStatus})
            setError(WrapperErrorMessages.WRAPPER_GENERIC);
        }

    }, [customer.email, customer.enrichment_data?.id_payer_mp, transaction.total_amount, bottler.payment_ga_config?.public_key, initLogin, renderStatusScreenBrick, setTransaction, setConsumerResponse, setError]);

    useEffect(() => {
        renderStatus();
    }, [renderStatus]);

    return (
        <div id="responseBack">
            <WrapperError/>
            <main>
                <Header/>
                <section className="payment-form">
                    <div className="container container__result">
                        <div className="content">
                            <div id="statusScreenBrick_container"/>
                            <PaymentResultsSection/>
                        </div>

                        <div className="button-layout">
                            <GoBackButton/>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}