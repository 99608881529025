export enum MercadoPagoErrors {
    INSUFICCIENT_FUNDS = "cc_rejected_insufficient_amount",
    INCORRECT_CVV = "cc_rejected_bad_filled_security_code",
    INCORRECT_EXPIRATION_DATE = "cc_rejected_bad_filled_date",
    EXPIRED_CARD = "cc_rejected_bad_filled_date",
    DECLINED_BY_MERCADOPAGO = "cc_rejected_call_for_authorize",
    OTHER_DECLINED_REASONS = "cc_rejected_other_reason"
}

export enum MercadoPagoErrorsMessages {
    INSUFICCIENT_FUNDS = "Lo sentimos, la transacción fue rechazada debido a fondos insuficientes en su cuenta. Utilice una tarjeta diferente o regrese a en tu hogar by Coca-Cola y seleccione otro método de pago.",
    INCORRECT_CVV = "Lo sentimos, la transacción fue rechazada debido a que el CVV es incorrecto. Favor de verificarlo.",
    INCORRECT_EXPIRATION_DATE = "Lo sentimos, la transacción fue rechazada debido a que la fecha de vencimiento es incorrecta. Favor de verificarla.",
    EXPIRED_CARD = "Lo sentimos, la transacción fue rechazada debido a que la tarjeta se encuentra vencida. Utilice una tarjeta diferente o regrese a en tu hogar by Coca-Cola y seleccione otro método de pago.",
    DECLINED_BY_MERCADOPAGO = "Lo sentimos, por razones de seguridad tuvimos que rechazar tu pago. Favor de utilizar una tarjeta diferente o regrese a en tu hogar by Coca-Cola y seleccione otro método de pago.",
    OTHER_DECLINED_REASONS = "Lo sentimos, tu pago no pudo ser procesado. Favor de utilizar una tarjeta diferente o regrese a en tu hogar by Coca-Cola y seleccione otro método de pago. "
}

export const MercadoPagoMessages = {
    "cc_rejected_insufficient_amount" : "Lo sentimos, la transacción fue rechazada debido a fondos insuficientes en su cuenta. Utilice una tarjeta diferente o regrese a en tu hogar by Coca-Cola y seleccione otro método de pago.",
    "cc_rejected_bad_filled_security_code" : "Lo sentimos, la transacción fue rechazada debido a que el CVV es incorrecto. Favor de verificarlo.",
    "cc_rejected_bad_filled_date" : "Lo sentimos, la transacción fue rechazada debido a que la fecha de vencimiento es incorrecta. Favor de verificarla.",
    "cc_rejected_bad_filled_other" : "Lo sentimos, la transacción fue rechazada debido a que la tarjeta se encuentra vencida. Utilice una tarjeta diferente o regrese a en tu hogar by Coca-Cola y seleccione otro método de pago.",
    "cc_rejected_call_for_authorize" : "Lo sentimos, por razones de seguridad tuvimos que rechazar tu pago. Favor de utilizar una tarjeta diferente o regrese a en tu hogar by Coca-Cola y seleccione otro método de pago.",
    "cc_rejected_other_reason" : "Lo sentimos, tu pago no pudo ser procesado. Favor de utilizar una tarjeta diferente o regrese a en tu hogar by Coca-Cola y seleccione otro método de pago."
}
