import {useCallback, useContext} from "react";
import {WrapperContext} from "../context/WrapperContext";
import {WrapperLayerAPI} from "../apis/WrapperLayerAPI";
import {WrapperErrorMessages} from "../errors/WrapperErrorMessages";
import { logger } from "../utils/logger";

export const useRedirect = () => {
    const {wrapperState, setError, setRedirectOn, setToken} = useContext(WrapperContext);
    const {transaction, customer, consumerResponse, redirectOn, token} = wrapperState;

    const generateTokenAndSaveTransaction = useCallback(async () => {      
        const {redirect_url, ...response} = consumerResponse;
        const payer_id = response?.payer_id || customer.enrichment_data?.id_payer_mp;
        let tokenData = '';
        try {
            const token = await WrapperLayerAPI.tokenizeResponse({
                ...response,
                payer_id,
                transaction_amount: transaction.total_amount,
                payer_email: customer.email,
                reference_id: transaction.reference_id,
                redirect_url,
                payment_status: redirectOn.payment_status
            });

            if(token.data?.token){
                setToken(token.data?.token);
                tokenData = token.data?.token;
            }

            return tokenData;
        } catch (error) {
            logger.error(String(error));
            setError(WrapperErrorMessages.WRAPPER_GENERIC);
        }
    }, [setError, consumerResponse, customer.email, customer.enrichment_data?.id_payer_mp, transaction.total_amount, transaction.reference_id, setToken, redirectOn]);

    const handleReturn = useCallback(async () => {      
        const {redirect_url} = consumerResponse;  

        try {
            if(!redirectOn.status) {
                setRedirectOn({status: true});
                let data: string | undefined = token;

                if (!data) {
                    data = await generateTokenAndSaveTransaction();
                } 

                if(data){
                    window.location.href = `${redirect_url}?token=${data}`; 
                }
            }
        } catch (error) {
            logger.error(String(error));
            setError(WrapperErrorMessages.WRAPPER_GENERIC);
        }
    }, [setError, consumerResponse, setRedirectOn, redirectOn, token, generateTokenAndSaveTransaction]);

    return {
        handleReturn,
        generateToken: generateTokenAndSaveTransaction
    };
};
